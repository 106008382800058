import React from "react";
import {Grid2, Link} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {ArrowForwardIosOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {ProductCategoryDTO} from "../../../../context/SocietyProvider";

interface CategoryPathProps {
    categoryPath: ProductCategoryDTO[],
    whiteBackground?: boolean,
}

const CategoryPath = ({categoryPath, whiteBackground = false}: CategoryPathProps) => {

    const navigate = useNavigate();

    return (
        <Grid2 container>
            <Grid2 container>
                <Grid2 sx={{alignContent: 'center'}}>
                    <Link
                        sx={{
                            fontSize: {xs: 16, sm: 18},
                            textAlign: 'left',
                            mr: 0,
                            ml: 0,
                        }}
                        variant={whiteBackground ? "firstVariant" : "fourthVariant"}
                        underline={'hover'}
                        onClick={() => navigate(`/articles`)}
                    >
                        Tous les articles
                    </Link>
                </Grid2>
                {categoryPath.length > 0 && (
                    <Grid2 sx={{alignContent: 'center'}}>
                        <IconButton
                            aria-label="ArrowForward"
                            variant="firstVariant"
                            sx={{
                                mr: 0,
                                ml: 0,
                                backgroundColor: 'secondary.main',
                                color: 'red',
                            }}
                            disabled
                        >
                            <ArrowForwardIosOutlined/>
                        </IconButton>
                    </Grid2>
                )}
            </Grid2>

            {categoryPath.map((category: ProductCategoryDTO, index) =>
                (
                    <Grid2 key={category.id} container>
                        <Grid2 sx={{alignContent: 'center'}}>
                            <Link
                                sx={{
                                    fontSize: {xs: 16, sm: 18},
                                    textAlign: 'left',
                                    mr: 0,
                                }}
                                variant={whiteBackground ? "firstVariant" : "fourthVariant"}
                                underline={'hover'}
                                onClick={() => navigate(`/categories/${category.id}`)}
                            >
                                {category.name}
                            </Link>
                        </Grid2>
                        {index !== categoryPath?.length - 1 && (
                            <Grid2 sx={{alignContent: 'center'}}>
                                <IconButton
                                    aria-label="ArrowForward"
                                    variant="firstVariant"
                                    sx={{
                                        mr: 0,
                                        backgroundColor: 'secondary.main',
                                        color: 'red',
                                    }}
                                    disabled
                                >
                                    <ArrowForwardIosOutlined/>
                                </IconButton>
                            </Grid2>
                        )}
                    </Grid2>
                ))}
        </Grid2>
    );
}

export default CategoryPath