import {
    ProductDTO
} from "../components/societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import useSociety from "./useSociety";
import useDataFetch from "./useDataFetch";

const useProduct = (productId: number): {product: ProductDTO} => {

    const {society} = useSociety();

    const {data: product} = useDataFetch<ProductDTO>(`/societies/${society.id}/products/${productId}`, false, true)

    return {product};
}

export default useProduct;