import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import useDataFetch from "../../hooks/useDataFetch";
import styles from "./RegistrationConfirmation.module.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useSectionsRefs} from "../../components/mainWrapper/MainWrapper";

interface RegisterConfirmationResponse {
    uid: string,
    society: string,
    subDomain: string
}

const RegistrationConfirmation = () => {

    const {
        setNavItems,
        setDisplayRegisterSocietyButton,
        setDisplayLoginButton,
        setDisplayMobileMenu
    } = useSectionsRefs();

    useEffect(() => {
        setNavItems([]);
        setDisplayRegisterSocietyButton(false);
        setDisplayLoginButton(false);
        setDisplayMobileMenu(false);
        window.scrollTo(0, 0);
    }, [setDisplayLoginButton, setNavItems, setDisplayRegisterSocietyButton, setDisplayMobileMenu]);

    const [searchParams, setSearchParams] = useSearchParams();
    const confirmationUid = searchParams.get("confirmationUid")

    const {
        data,
        error,
    } = useDataFetch<RegisterConfirmationResponse>(`/auth/confirm?confirmationUid=${confirmationUid}`, false, !!confirmationUid)

    return (
        <>
            <Box sx={{
                width: "100%",
                backgroundColor: 'primary.main',
                alignContent: 'center'
            }}>

                <Box
                    sx={{
                        backgroundColor: "primary.main",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className={styles.content}>
                        <div className={styles.realContent}>
                            <Typography
                                variant={"secondVariant"}
                                sx={{
                                    fontSize: {xs: 22, sm: 26, md: 30},
                                    mb: 3,
                                }}
                            >
                                Confirmation d'inscription
                            </Typography>

                            {data && <div>

                                <Typography
                                    variant={"secondVariant"}
                                    sx={{
                                        fontSize: {xs: 16, sm: 18, md: 20},
                                        mb: 3,
                                    }}
                                >
                                    {data.society ? (
                                        <>
                                            Vous pouvez dès maintenant accéder à l'espace d'administration de votre page
                                            Looty
                                            en vous connectant sur:
                                            <a href={`https://${data.subDomain}.looty.ch`}>{`https://${data.subDomain}.looty.ch`}</a>
                                        </>
                                    ) : (
                                        <>
                                            Votre compte a bien été activé ! Rendez-vous sur <a href={`https://looty.ch`}>{`https://looty.ch`}</a> pour vous connecter et y découvrir les commerçants !
                                        </>
                                    )}
                                </Typography>

                            </div>}

                            {!confirmationUid &&
                                <div>
                                    <Typography
                                        variant={"secondVariant"}
                                        sx={{
                                            fontSize: {xs: 16, sm: 18, md: 20},
                                            mb: 3,
                                        }}
                                    >
                                        Paramètre invalide, veuillez vérifier l'url
                                    </Typography>
                                </div>
                            }

                            {(!confirmationUid || (error && error.status === 404)) &&
                                <div>
                                    <Typography
                                        variant={"secondVariant"}
                                        sx={{
                                            fontSize: {xs: 16, sm: 18, md: 20},
                                            mb: 3,
                                        }}
                                    >
                                        Ce lien de validation n'est pas correct.
                                    </Typography>
                                </div>
                            }

                            {(error && error.status === 410) &&
                                <div>
                                    <Typography
                                        variant={"secondVariant"}
                                        sx={{
                                            fontSize: {xs: 16, sm: 18, md: 20},
                                            mb: 3,
                                        }}
                                    >
                                        Vous avez déjà confirmé votre compte. Veuillez vous connecter.
                                    </Typography>
                                </div>
                            }
                        </div>
                    </div>
                </Box>
            </Box>
        </>
    );
}

export default RegistrationConfirmation