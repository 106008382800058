import React, {useEffect, useState} from "react";
import {useSectionsRefs} from "../../components/mainWrapper/MainWrapper";
import styles from "./CreateAccount.module.css";
import {Alert, Button, Grid2} from "@mui/material";
import Box from "@mui/material/Box";
import RegisterStepper from "../../components/registerStepper/RegisterStepper";
import Typography from "@mui/material/Typography";
import useDataPost from "../../hooks/useDataPost";
import RegistrationConfirmationStep
    from "../../components/registerStepper/registrationConfirmationStep/RegistrationConfirmationStep";
import {StepDescription, SuccessfullRegisterResponseDTO, ValidateMailNotInUseResponseDTO} from "../register/Register";
import LoginData from "../../components/registerStepper/loginData/LoginData";
import PersonnalDataCreateAccount
    from "../../components/registerStepper/personnalDataCreateAccount/PersonnalDataCreateAccount";

const Register = () => {

    const {
        isLoading,
        data,
        postData
    } = useDataPost<SuccessfullRegisterResponseDTO>(`/auth/create-account`, {}, false, false, false);

    const {data: validateMailNotInUseResponse, postDataPromise: postDataValidateMail}
        = useDataPost<ValidateMailNotInUseResponseDTO>(`/auth/validate-mail-not-in-use`, {}, false, false, false);

    const [activeStep, setActiveStep] = useState<number>(0);

    const [error, setError] = useState<string>('');

    // LoginData
    const [mailLoginData, setMailLoginData] = useState<string>('');
    const [mailConfirmationLoginData, setMailConfirmationLoginData] = useState<string>('');
    const [passwordLoginData, setPasswordLoginData] = useState<string>('');
    const [passwordConfirmationLoginData, setPasswordConfirmationLoginData] = useState<string>('');

    // PersonnalData
    const [firstnamePersonnalData, setFirstnamePersonnalData] = useState<string>('');
    const [lastnamePersonnalData, setLastnamePersonnalData] = useState<string>('');
    const [zipPersonnalData, setZipPersonnalData] = useState<string>('');
    const [cityPersonnalData, setCityPersonnalData] = useState<string>('');
    const [streetPersonnalData, setStreetPersonnalData] = useState<string>('');
    const [streetNumberPersonnalData, setStreetNumberPersonnalData] = useState<string>('');

    const stepsDescriptions: StepDescription[] = [
        {
            stepTitle: "Données de connexion",
            stepNumber: 0,
            stepComponent: (
                <LoginData
                    mail={mailLoginData}
                    setMail={setMailLoginData}
                    mailConfirmation={mailConfirmationLoginData}
                    setMailConfirmation={setMailConfirmationLoginData}
                    password={passwordLoginData}
                    setPassword={setPasswordLoginData}
                    passwordConfirmation={passwordConfirmationLoginData}
                    setPasswordConfirmation={setPasswordConfirmationLoginData}
                />
            ),
            stepDescriptionDataValidation: [
                {
                    validationMethod: () => {
                        if (mailLoginData !== mailConfirmationLoginData) {
                            setError("Les adresses email ne correspondent pas.")
                            return Promise.resolve(false);
                        }
                        setError('');
                        return Promise.resolve(true);
                    }
                },
                {
                    validationMethod: () => {
                        if (passwordLoginData.length < 8) {
                            setError("Le mot de passe doit faire au moins 8 caractères.")
                            return Promise.resolve(false);
                        }
                        setError('');
                        return Promise.resolve(true);
                    }
                },
                {
                    validationMethod: () => {
                        if (passwordLoginData !== passwordConfirmationLoginData) {
                            setError("Les mots de passe ne correspondent pas.")
                            return Promise.resolve(false);
                        }
                        setError('');
                        return Promise.resolve(true);
                    }
                },
                {
                    validationMethod: async () => {
                        const response = await postDataValidateMail(undefined, {
                            email: mailLoginData,
                        });

                        if (response.data.mailAlreadyInUse) {
                            setError("Cette adresse email est déjà utilisée.");
                            return false;
                        }
                        setError('');
                        return true;
                    }
                },
                {
                    validationMethod: async () => {
                        const response = await postDataValidateMail(undefined, {
                            email: mailLoginData,
                        });

                        if (response.data.mailAlreadyInUse) {
                            setError("Cette adresse email est déjà utilisée.");
                            return false;
                        }
                        setError('');
                        return true;
                    }
                },
            ],
        },
        {
            stepTitle: "Données personnelles",
            stepNumber: 1,
            stepComponent: (
                <PersonnalDataCreateAccount
                    firstname={firstnamePersonnalData}
                    setFirstname={setFirstnamePersonnalData}
                    lastname={lastnamePersonnalData}
                    setLastname={setLastnamePersonnalData}
                    zip={zipPersonnalData}
                    setZip={setZipPersonnalData}
                    city={cityPersonnalData}
                    setCity={setCityPersonnalData}
                    street={streetPersonnalData}
                    setStreet={setStreetPersonnalData}
                    streetNumber={streetNumberPersonnalData}
                    setStreetNumber={setStreetNumberPersonnalData}
                />
            ),
            stepDescriptionDataValidation: []
        },
    ]

    const {
        setNavItems,
        setDisplayRegisterSocietyButton,
        setDisplayLoginButton,
        setDisplayMobileMenu
    } = useSectionsRefs();

    useEffect(() => {
        setNavItems([]);
        setDisplayRegisterSocietyButton(false);
        setDisplayLoginButton(false);
        setDisplayMobileMenu(false);
        window.scrollTo(0, 0);
    }, [activeStep, setDisplayLoginButton, setNavItems, setDisplayRegisterSocietyButton, setDisplayMobileMenu]);

    const handleNext = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const currentStepDescriptionDataValidation = stepsDescriptions[activeStep].stepDescriptionDataValidation;

        let isDataValid = true;
        for (const dataValidation of currentStepDescriptionDataValidation) {
            const isValid = await dataValidation.validationMethod();
            if (!isValid) {
                isDataValid = false;
                break;
            }
        }

        if (!isDataValid) {
            return;
        }

        if (activeStep < stepsDescriptions.length - 1) {
            setActiveStep(activeStep + 1);
        }

        if (activeStep === stepsDescriptions.length - 1) {
            const data = {
                email: mailLoginData,
                password: passwordLoginData,
                firstname: firstnamePersonnalData,
                lastname: lastnamePersonnalData,
                zip: zipPersonnalData,
                city: cityPersonnalData,
                street: streetPersonnalData,
                streetNumber: streetNumberPersonnalData,
            }

            postData(undefined, data);
        }
    }

    const handleBack = () => {
        setError('');
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    }

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: 'primary.main',
            alignContent: 'center'
        }}>

            <Box
                sx={{
                    backgroundColor: "primary.main",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <div className={styles.realContent}>
                        {!data ? (
                            <>
                                <RegisterStepper activeStep={activeStep} stepsDescriptions={stepsDescriptions}/>
                                <Box
                                    sx={{
                                        pl: "10px",
                                        pr: "10px",
                                        pb: "10px",
                                    }}
                                >
                                    <form onSubmit={(e) => handleNext(e)}>
                                        <Typography
                                            variant={"secondVariant"}
                                            sx={{
                                                fontSize: {xs: 22},
                                                display: {xs: 'block', sm: 'none'},
                                                mb: 3,
                                            }}
                                        >
                                            {stepsDescriptions[activeStep].stepTitle}
                                        </Typography>

                                        {stepsDescriptions[activeStep].stepComponent}

                                        {error && (
                                            <Alert
                                                severity="error"
                                                sx={{mb: 4}}
                                            >
                                                {error}
                                            </Alert>
                                        )}

                                        <Grid2 container>
                                            <Grid2 size={6} textAlign={"left"}>
                                                <Button
                                                    sx={{
                                                        display: activeStep > 0 ? 'block' : 'none'
                                                    }}
                                                    onClick={() => handleBack()}
                                                    variant={"fourthVariant"}
                                                >
                                                    Précédent
                                                </Button>
                                            </Grid2>
                                            <Grid2 size={6} textAlign={"right"}>
                                                <Button
                                                    type={"submit"}
                                                    variant={"fourthVariant"}
                                                    disabled={isLoading}
                                                >
                                                    {activeStep === stepsDescriptions.length - 1 ? 'S\'inscrire' : 'Suivant'}
                                                </Button>
                                            </Grid2>
                                        </Grid2>
                                    </form>
                                </Box>
                            </>
                        ) : (
                            <RegistrationConfirmationStep email={data.email} isSociety={false}/>
                        )}

                    </div>
                </div>
            </Box>
        </Box>

    );
}

export default Register