import React from "react";
import CustomTextField from "../../customMUIComponents/CustomTextField";

interface LoginDataProps {
    mail: string,
    setMail: (mail: string) => void,
    mailConfirmation: string,
    setMailConfirmation: (mailConfirmation: string) => void,
    password: string,
    setPassword: (mail: string) => void,
    passwordConfirmation: string,
    setPasswordConfirmation: (mail: string) => void,
}

const LoginData = ({
                       mail,
                       setMail,
                       mailConfirmation,
                       setMailConfirmation,
                       password,
                       setPassword,
                       passwordConfirmation,
                       setPasswordConfirmation
                   }: LoginDataProps) => {

    return (
        <>
            <CustomTextField
                type={"email"}
                value={mail}
                setField={setMail}
                label="Adresse e-mail"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                type={"email"}
                value={mailConfirmation}
                setField={setMailConfirmation}
                label="Confirmation e-mail"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                type={"password"}
                value={password}
                setField={setPassword}
                label="Mot de passe"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                type={"password"}
                value={passwordConfirmation}
                setField={setPasswordConfirmation}
                label="Confirmation du mot de passe"
                required
                mb={4}
                variant={"outlined"}
            />
        </>
    );
}

export default LoginData