import React, {useEffect} from "react";
import styles from "./SocietyAdmin.module.css";
import {useSectionsRefs} from "../../../components/mainWrapper/MainWrapper";
import SocietyAdminTheme from "../../../components/societyAdmin/societyAdminTheme/SocietyAdminTheme";
import SocietyAdminLogo from "../../../components/societyAdmin/societyAdminLogo/SocietyAdminLogo";
import SocietyAdminCategories from "../../../components/societyAdmin/societyAdminCategories/SocietyAdminCategories";

const SocietyHomepage = () => {

    const {setNavItems, setDisplayRegisterSocietyButton, setDisplayCart} = useSectionsRefs();

    useEffect(() => {
        setNavItems([])
        setDisplayRegisterSocietyButton(false);
        setDisplayCart(false);
    }, [setDisplayRegisterSocietyButton, setNavItems]);

    return (
        <div className={styles.container}>
            <SocietyAdminTheme/>
            <SocietyAdminLogo/>
            <SocietyAdminCategories/>
        </div>

    );
}

export default SocietyHomepage