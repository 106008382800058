import React from "react";
import CustomTextField from "../../customMUIComponents/CustomTextField";

interface PersonnalDataProps {
    firstname: string,
    setFirstname: (firstname: string) => void,
    lastname: string,
    setLastname: (lastname: string) => void,
    zip: string,
    setZip: (zip: string) => void,
    city: string,
    setCity: (city: string) => void,
    street: string,
    setStreet: (street: string) => void,
    streetNumber: string,
    setStreetNumber: (streetNumber: string) => void,
}

const PersonnalData = ({
                           firstname,
                           setFirstname,
                           lastname,
                           setLastname,
                           zip,
                           setZip,
                           city,
                           setCity,
                           street,
                           setStreet,
                           streetNumber,
                           setStreetNumber,
                       }: PersonnalDataProps) => {

    return (
        <>
            <CustomTextField
                value={firstname}
                setField={setFirstname}
                label="Prénom"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={lastname}
                setField={setLastname}
                label="Nom de famille"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={zip}
                setField={setZip}
                label="NPA"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={city}
                setField={setCity}
                label="Localité"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={street}
                setField={setStreet}
                label="Rue"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={streetNumber}
                setField={setStreetNumber}
                label="N° de rue"
                required
                mb={4}
                variant={"outlined"}
            />
        </>
    );
}

export default PersonnalData