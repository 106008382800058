import {createContext, Dispatch, ReactNode, SetStateAction, useState} from "react";

export interface SocietyDTO {
    id?: number,
    name?: string,
    subdomain?: string,
    description?: string,
    theme?: string,
    image?: string,
    enabled?: boolean,
    visible?: boolean,
    secured?: boolean,
    societyAdminDetails?: SocietyAdminDetailsDTO,
    societyContactDetails?: SocietyContactDetailsDTO,
    categories?: ProductCategoryDTO[],
}

export interface ProductCategoryDTO {
    id: number,
    name: string,
    parentCategoryId: number,
    subcategories: ProductCategoryDTO[],
}

export interface SocietyAdminDetailsDTO {
    selling?: boolean,
}

export interface SocietyContactDetailsDTO {
    city: string,
    zip: string,
    street: string,
    streetNumber: string,
    lat: number,
    lng: number,
}

interface SocietyContextInterface {
    society: SocietyDTO
    setSociety: Dispatch<SetStateAction<SocietyDTO>>
}

const SocietyContext = createContext<SocietyContextInterface>({
    society: {},
    setSociety: () => {
    }
});

interface SocietyProviderProps {
    children: ReactNode
}

export const SocietyProvider = ({children}: SocietyProviderProps) => {
    const [society, setSociety] = useState<SocietyDTO>({});

    return (
        <SocietyContext.Provider value={{
            society,
            setSociety
        }}>
            {children}
        </SocietyContext.Provider>
    )
}

export default SocietyContext;