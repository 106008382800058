import React, {useState} from "react";
import {Badge, Card, CardActionArea, CardContent, Grid2} from "@mui/material";
import Box from "@mui/material/Box";
import styles from "./ArticleCard.module.css";
import Typography from "@mui/material/Typography";
import {ProductDTO} from "../societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import useSociety from "../../hooks/useSociety";
import IconButton from "@mui/material/IconButton";
import {AddShoppingCart} from "@mui/icons-material";
import {useCart} from "../../hooks/useCart";
import useFormatNumber from "../../hooks/useFormatNumber";
import Button from "@mui/material/Button";
import AddArticleModal from "../societyAdmin/societyAdminArticles/addArticleModal/AddArticleModal";
import {useNavigate} from "react-router-dom";

interface ArticleCardProps {
    article: ProductDTO
}

const ArticleCard = ({article}: ArticleCardProps) => {

    const [imgError, setImgError] = React.useState(false);

    const [open, setOpen] = useState<boolean>(false);

    const {society} = useSociety();

    const cart = useCart();

    const {formatCentsToPrice} = useFormatNumber();

    const navigate = useNavigate();

    const addToCart = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (!article.stockDefined || (cart.getProductQuantity(article.id) + 1 <= article.stock)) {
            cart.addToCart(article, 1);
        }
    }

    const modifyArticle = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setOpen(true);
    }

    return (
        <>
            <Grid2
                key={article.name! + article.id}
                size={{xs: 6, sm: 6, md: 6, lg: 3}}
            >
                <Card
                    sx={{
                        borderRadius: 2,
                        // maxWidth: {xs: '300px', sm: '450px'},
                        margin: 'auto',
                    }}
                    onClick={() => navigate(`/articles/${article.id}`)}
                >
                    <CardActionArea>
                        <CardContent
                            sx={{
                                paddingBottom: '0px',
                            }}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems={"center"}
                                overflow="hidden"
                                sx={{
                                    mb: 2,
                                    height: '150px',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    paddingBottom: '0px',
                                    backgroundColor: '#ffffff',
                                }}
                            >
                                <img
                                    className={styles.societyImage}
                                    src={imgError ? 'missing-image.png' : `https://looty.ch:8443/api/v1/societies/${society.id}/products/${article.id}/image`}
                                    onError={({currentTarget}) => {
                                        setImgError(true);
                                    }}
                                    alt="society"/>
                            </Box>
                            <Typography
                                textAlign='left'
                                sx={{
                                    color: 'black',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {formatCentsToPrice(article.price)}.-
                            </Typography>
                            <Box
                                sx={{
                                    height: '60px',
                                }}
                            >
                                <Typography
                                    textAlign='left'
                                    sx={{
                                        color: 'black',
                                        fontSize: '18px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: '2',
                                        WebkitBoxOrient: 'vertical',
                                    }}
                                >
                                    {article.name}
                                </Typography>
                            </Box>

                        </CardContent>
                    </CardActionArea>

                    <Grid2 container sx={{paddingLeft: '16px', paddingRight: '16px'}}>
                        <Grid2
                            size={10}
                            alignContent={'center'}
                            textAlign={'left'}
                        >
                            <Typography
                                color={!article.stockDefined || article.stock > 10 ? 'success' : 'warning'}
                                fontSize={'12px'}
                            >
                                {!article.stockDefined ? 'Disponible' : article.stock > 10 ? 'Plus de 10 pièces disponibles' : article.stock === 1 ? '1 pièce disponible' : `${article.stock} pièces disponibles`}
                            </Typography>

                        </Grid2>
                        <Grid2 size={2}>
                            <IconButton
                                sx={{
                                    display: {xs: 'inline-flex'},
                                    paddingRight: {xs: '0px', sm: '0px'},
                                }}
                                aria-label="Ajouter au panier"
                                color={'primary'}
                                onClick={(e) => addToCart(e)}
                            >
                                <Badge badgeContent={cart.getProductQuantity(article.id)} color={"secondary"}>
                                    <AddShoppingCart
                                        sx={{
                                            fontSize: {xs: '25px', sm: '25px'},
                                            padding: '0px',
                                            paddingRight: '0px',
                                        }}
                                    />
                                </Badge>
                            </IconButton>
                        </Grid2>
                    </Grid2>
                    {society.secured && (
                        <>
                            <Grid2>
                                <Button
                                    variant={"fourthVariant"}
                                    fullWidth
                                    onClick={(e) => modifyArticle(e)}
                                >
                                    Modifier l'article
                                </Button>
                            </Grid2>
                        </>
                    )}
                </Card>
            </Grid2>
            <AddArticleModal open={open} setOpen={setOpen} product={article}/>
        </>
    );
}

export default ArticleCard