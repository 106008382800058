import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../customMUIComponents/CustomTextField";
import useDataPost from "../../../../hooks/useDataPost";
import {SocietyDTO} from "../../../../context/SocietyProvider";
import useSociety from "../../../../hooks/useSociety";
import IconButton from "@mui/material/IconButton";
import {DeleteOutline} from "@mui/icons-material";
import {Dialog, DialogContent, DialogTitle, Grid2, MenuItem} from "@mui/material";
import {ProductDTO} from "../societyAdminArticlesList/SocietyAdminArticlesList";
import useFormatNumber from "../../../../hooks/useFormatNumber";
import UseDataPut from "../../../../hooks/useDataPut";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import DeleteArticleModal from "../deleteArticleModal/DeleteArticleModal";

interface AddArticleModalProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    product?: ProductDTO,
}

const AddArticleModal = ({open, setOpen, product}: AddArticleModalProps) => {
    const {society} = useSociety();

    const {formatCentsToPrice} = useFormatNumber();

    const [title, setTitle] = useState<string>(product?.name || '');
    const [description, setDescription] = useState<string>(product?.description || '');
    const [price, setPrice] = useState<string>((product?.price && formatCentsToPrice(product.price)) || '');
    const [isStockDefined, setIsStockDefined] = useState<boolean | undefined>(product ? product.stockDefined : undefined);
    const [stock, setStock] = useState<string>((product?.stock && product.stock.toString()) || '');
    const [image, setImage] = useState<File | null>(null);
    const [keepImage, setKeepImage] = useState<boolean>(!!product?.imageName);

    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const handleSetPrice = (value: string) => {
        const dec = value.indexOf(".");
        const tooLong = value.length > dec + 3;
        const invalidNum = isNaN(parseFloat(value));

        if ((dec >= 0 && tooLong) || invalidNum) {
            setPrice(value.slice(0, -1));
            return;
        }
        setPrice(value);
    }

    const {postData} = useDataPost<SocietyDTO>(`/societies/${society.id}/products`, {}, true, false, true);
    const {putData} = UseDataPut<SocietyDTO>(`/societies/${society.id}/products/${product?.id}`, {}, true, false, true);

    const handleClose = () => {
        setOpen(false);
    }

    const addOrModifyProduct = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData: any = new FormData();
        formData.append('name', title);
        formData.append('description', description);
        formData.append('price', Number(price) * 100);
        formData.append('stockDefined', isStockDefined);
        if (isStockDefined) {
            formData.append('stock', stock);
        }
        image && formData.append('image', image);
        formData.append('keepImage', keepImage);
        formData.append('visible', true);

        if (product) {
            await putData(undefined, formData);
        } else {
            await postData(undefined, formData);
        }
        window.location.reload();
    };

    useEffect(() => {
        if (product) {
            setTitle(product.name);
            setDescription(product.description);
            setPrice(formatCentsToPrice(product.price));
            setIsStockDefined(product.stockDefined);
            setStock(product.stock.toString());
            setKeepImage(!!product?.imageName);
            setImage(null);
        }
    }, [open]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    textAlign: "center",
                }}
            >
                <DialogTitle sx={{mt: -5}}>
                    {product ? 'Modifier un article' : 'Ajouter un article'}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => addOrModifyProduct(e)}>
                        <Typography
                            id="modal-modal-title"
                            variant="secondVariant"
                            component="h2"
                            sx={{marginBottom: 2}}
                            fontSize={{xs: 22, sm: 24, md: 26}}
                        >
                            {product ? 'Modifier un article' : 'Ajouter un article'}
                        </Typography>

                        <CustomTextField
                            value={title}
                            label="Titre"
                            mb={4}
                            type="text"
                            required
                            setField={setTitle}
                            variant="outlined"
                        />

                        <CustomTextField
                            value={description}
                            label="Description"
                            mb={4}
                            type="text"
                            required
                            setField={setDescription}
                            variant="outlined"
                            multiline={true}
                            rows={4}
                        />

                        <CustomTextField
                            value={price}
                            label="Prix"
                            mb={4}
                            type="number"
                            step={"0.01"}
                            min={0.01}
                            required
                            setField={handleSetPrice}
                            variant="outlined"
                            endAdornment={"CHF"}
                        />

                        <CustomTextField
                            variant={"outlined"}
                            value={isStockDefined === undefined ? "" : isStockDefined ? "yes" : "no"}
                            label={"Définir un stock"}
                            setField={(fieldValue) => setIsStockDefined(fieldValue === "yes")}
                            select
                            selectOptions={[
                                <MenuItem key="yes" value={'yes'}>Oui</MenuItem>,
                                <MenuItem key="no" value={'no'}>Non</MenuItem>
                            ]}
                            mb={4}
                            textAlign={"left"}
                            required
                        />

                        {isStockDefined && (
                            <CustomTextField
                                value={stock}
                                label="Nombre de pièces en stock"
                                mb={4}
                                type="number"
                                step={"1"}
                                min={0}
                                required
                                setField={setStock}
                                variant="outlined"
                            />
                        )}

                        <CustomTextField
                            variant={"outlined"}
                            value={isStockDefined === undefined ? "" : isStockDefined ? "yes" : "no"}
                            label={"Modifier la catégorie"}
                            setField={(fieldValue) => setIsStockDefined(fieldValue === "yes")}
                            select
                            selectOptions={[
                                <MenuItem key="yes" value={'yes'}>Oui</MenuItem>,
                                <MenuItem key="no" value={'no'}>Non</MenuItem>
                            ]}
                            mb={4}
                            textAlign={"left"}
                            required
                        />

                        {((image === null && !product) || (product && !keepImage && image === null)) && (
                            <>
                                <input
                                    accept="image/*"
                                    style={{display: 'none'}}
                                    id="raised-button-file"
                                    type="file"
                                    onChange={(event) => {
                                        if (event.target.files && event.target.files.length > 0) {
                                            if (event.target.files[0].size > 10485760) {
                                                alert('Fichier trop volumineux. Max 10Mb.')
                                            } else {
                                                setImage(event.target.files[0]);
                                            }
                                        }
                                    }}
                                />
                                <label htmlFor="raised-button-file">
                                    <Button variant="secondVariant" component="span">
                                        Ajouter une image
                                    </Button>
                                </label>
                            </>
                        )}

                        <Box
                            margin={"auto"}
                            width={"fit-content"}
                        >
                            <Grid2 container>
                                <Grid2>
                                    <Typography
                                        mt={1}
                                        variant={"secondVariant"}
                                    >
                                        {!product ? image?.name : keepImage ? product.imageName : image?.name}
                                    </Typography>
                                </Grid2>
                                <Grid2>
                                    {(image || keepImage) && (
                                        <IconButton
                                            aria-label="Remove"
                                            variant="thirdVariant"
                                            onClick={() => {
                                                setImage(null);
                                                setKeepImage(false);
                                            }}
                                        >
                                            <DeleteOutline
                                                sx={{
                                                    fontSize: {xs: '25px', sm: '25px'},
                                                    padding: '0px',
                                                }}
                                            />

                                        </IconButton>
                                    )}
                                </Grid2>
                            </Grid2>
                        </Box>


                        <Button
                            type="submit"
                            variant="firstVariant"
                            fullWidth
                            sx={{
                                mt: 2
                            }}
                        >
                            {product ? 'Modifier l\'article' : 'Ajouter l\'article'}
                        </Button>
                    </form>

                    <Divider sx={{mt: 2, mb: 2}}/>

                    <Button
                        variant={"fifthVariant"}
                        fullWidth
                        onClick={() => setOpenDeleteModal(true)}
                    >
                        Supprimer définitivement
                    </Button>

                </DialogContent>
            </Dialog>
            {product && (
                <DeleteArticleModal open={openDeleteModal} setOpen={setOpenDeleteModal} product={product}/>
            )}
        </>
    );
}

export default AddArticleModal