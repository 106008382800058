import React, {useState} from "react";
import Box from "@mui/material/Box";
import {RichTreeView} from '@mui/x-tree-view/RichTreeView';
import {
    TreeItem2,
    TreeItem2Label,
    TreeItem2LabelInput,
    TreeItem2Props,
    TreeViewBaseItem,
    UseTreeItem2LabelInputSlotOwnProps,
    UseTreeItem2LabelSlotOwnProps,
    useTreeItem2Utils
} from "@mui/x-tree-view";
import IconButton from "@mui/material/IconButton";
import {AddRounded, Check, CloseRounded, DeleteOutline, EditOutlined, ErrorOutline} from "@mui/icons-material";
import styles from "../societyAdminLogo/SocietyAdminLogo.module.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useSociety from "../../../hooks/useSociety";
import {ProductCategoryDTO, SocietyDTO} from "../../../context/SocietyProvider";
import {Tooltip} from "@mui/material";
import {getPathToSubcategoryEntry} from "../../../pages/society/articles/articlesUtils";
import useDataPut from "../../../hooks/useDataPut";
import SocietyAdminCategoriesDeletionModal
    from "./societyAdminCategoriesDeletetionModal/SocietyAdminCategoriesDeletionModal";
import SocietyAdminCategoriesAddModal from "./societyAdminCategoriesAddModal/SocietyAdminCategoriesAddModal";

const ERRORS = {
    REQUIRED: 'Texte vide interdit',
    INVALID: 'Les chiffres ne sont pas autorisés',
    DUPLICATE: 'Nom déjà pris par une catégorie de même niveau.',
};

interface CustomLabelInputProps extends UseTreeItem2LabelInputSlotOwnProps {
    handleCancelItemLabelEditing: (event: React.SyntheticEvent) => void;
    handleSaveItemLabel: (event: React.SyntheticEvent, label: string) => void;
    value: string;
    error: null | keyof typeof ERRORS;
}

interface CustomLabelProps extends UseTreeItem2LabelSlotOwnProps {
    editable: boolean;
    editing: boolean;
    toggleItemEditing: () => void;
    toggleItemAddChild: () => void;
    toggleItemDeleting: () => void;
}

const convertCategoryToTreeViewCategory = (category: ProductCategoryDTO): TreeViewBaseItem => {
    return {
        id: category.id + '',
        label: category.name,
        children: category.subcategories.map((subCategory: ProductCategoryDTO) => convertCategoryToTreeViewCategory(subCategory))
    };
}

const SocietyAdminCategories = () => {

    const {society} = useSociety();

    const [addOpen, setAddOpen] = useState<boolean>(false);
    const [parentCategoryToAddChild, setParentCategoryToAddChild] = useState<ProductCategoryDTO>();

    const {putData} = useDataPut<SocietyDTO>(``, {}, true, false);

    const editProductCategory = (itemId: string, label: string) => {
        putData(`/societies/${society.id}/categories/${itemId}`, {
            productCategoryId: Number(itemId),
            name: label,
        }).then((response) => {
            window.location.reload();
        }).catch((error) => {
            window.location.reload();
        })
    }

    const addParentProductCategory = () => {
        setAddOpen(true);
    }

    const treeViewCategories: TreeViewBaseItem[] = [];
    society.categories?.forEach((category: ProductCategoryDTO) => {
        treeViewCategories.push(convertCategoryToTreeViewCategory(category));
    })

    function CustomLabel({
                             editing,
                             editable,
                             children,
                             toggleItemEditing,
                             toggleItemAddChild,
                             toggleItemDeleting,
                             ...other
                         }: CustomLabelProps) {
        return (
            <>
                <TreeItem2Label
                    {...other}
                    editable={editable}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        justifyContent: 'space-between',
                    }}
                >
                    {children}
                    {editable && (
                        <div>
                            <Tooltip title="Modifier">
                                <IconButton
                                    size="small"
                                    onClick={toggleItemEditing}
                                    sx={{color: 'text.secondary'}}
                                >
                                    <EditOutlined fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Ajouter une sous-catégorie">
                                <IconButton
                                    size="small"
                                    onClick={toggleItemAddChild}
                                    sx={{color: 'text.secondary'}}
                                >
                                    <AddRounded fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Supprimer">
                                <IconButton
                                    size="small"
                                    onClick={toggleItemDeleting}
                                    sx={{color: 'text.secondary'}}
                                >
                                    <DeleteOutline fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </TreeItem2Label>
            </>
        );
    }

    function CustomLabelInput(props: Omit<CustomLabelInputProps, 'ref'>) {

        const {handleCancelItemLabelEditing, handleSaveItemLabel, value, error, ...other} =
            props;

        return (
            <React.Fragment>
                <TreeItem2LabelInput {...other} value={value}/>

                {error ?
                    (

                        <Tooltip title={ERRORS[error]}>
                            <ErrorOutline color="error"/>
                        </Tooltip>
                    )
                    : (
                        <IconButton
                            color="success"
                            size="small"
                            onClick={(event: React.MouseEvent) => {
                                handleSaveItemLabel(event, value);
                            }}
                        >
                            <Check fontSize="small"/>
                        </IconButton>
                    )
                }

                <IconButton color="error" size="small" onClick={handleCancelItemLabelEditing}>
                    <CloseRounded fontSize="small"/>
                </IconButton>
            </React.Fragment>
        );
    }

    const CustomTreeItem2 = React.forwardRef(function CustomTreeItem2(
        props: TreeItem2Props,
        ref: React.Ref<HTMLLIElement>,
    ) {
        const [open, setOpen] = React.useState(false);
        const [categoryToBeDeleted, setCategoryToBeDeleted] = React.useState<ProductCategoryDTO>();

        const [error, setError] = React.useState<null | keyof typeof ERRORS>(null);

        const {interactions, status} = useTreeItem2Utils({
            itemId: props.itemId,
            children: props.children,
        });

        const {society} = useSociety();

        const validateLabel = (label: string, id: number) => {
            if (!label) {
                setError('REQUIRED');
                return;
            }

            if (/\d/.test(label)) {
                setError('INVALID');
                return;
            }

            const pathToSubcategoryEntry: ProductCategoryDTO[] | null = getPathToSubcategoryEntry(society, id);
            if (pathToSubcategoryEntry && pathToSubcategoryEntry.length > 0) {
                const parentCategoryId = pathToSubcategoryEntry[pathToSubcategoryEntry.length - 1].parentCategoryId;
                if (!parentCategoryId) {
                    const similarCategory = society.categories?.find((category: ProductCategoryDTO) => category.id !== id && category.name === label);
                    if (similarCategory) {
                        setError('DUPLICATE');
                        return;
                    }
                    setError(null);
                    return;
                }

                const parentCategory = pathToSubcategoryEntry[pathToSubcategoryEntry.length - 2];
                const sameLevelCategories = parentCategory.subcategories;

                const similarCategory = sameLevelCategories.find((category: ProductCategoryDTO) => category.id !== id && category.name === label);
                if (similarCategory) {
                    setError('DUPLICATE');
                    return;
                }
            }

            setError(null);
            return;
        };

        const handleContentDoubleClick: UseTreeItem2LabelSlotOwnProps['onDoubleClick'] = (
            event,
        ) => {
            event.defaultMuiPrevented = true;
        };

        const handleInputBlur: UseTreeItem2LabelInputSlotOwnProps['onBlur'] = (event) => {
            if (error) {
                event.defaultMuiPrevented = true;
            }
        };

        const handleInputKeyDown: UseTreeItem2LabelInputSlotOwnProps['onKeyDown'] = (
            event,
        ) => {
            event.defaultMuiPrevented = true;
            const target = event.target as HTMLInputElement;

            if (event.key === 'Enter' && target.value) {
                if (error) {
                    return;
                }
                setError(null);
                interactions.handleSaveItemLabel(event, target.value);
            } else if (event.key === 'Escape') {
                setError(null);
                interactions.handleCancelItemLabelEditing(event);
            }
        };

        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
            validateLabel(event.target.value, Number(id));
        };

        const handleAddCategoryChild = (itemId: string): void => {
            const pathToSubcategoryEntry: ProductCategoryDTO[] | null = getPathToSubcategoryEntry(society, Number(itemId));
            if (pathToSubcategoryEntry && pathToSubcategoryEntry.length > 0) {
                setParentCategoryToAddChild(pathToSubcategoryEntry[pathToSubcategoryEntry.length -1]);
                setAddOpen(true);
            }
        }

        const handleDeleteCategory = (itemId: string): void => {
            const pathToCategory = getPathToSubcategoryEntry(society, Number(itemId));
            if (pathToCategory) {
                const category: ProductCategoryDTO = pathToCategory[pathToCategory?.length - 1];
                setCategoryToBeDeleted(category);
                setOpen(true);
            }
        }

        return (
            <>
                <TreeItem2
                    {...props}
                    ref={ref}
                    slots={{label: CustomLabel, labelInput: CustomLabelInput}}
                    slotProps={{
                        label: {
                            onDoubleClick: handleContentDoubleClick,
                            editable: status.editable,
                            editing: status.editing,
                            toggleItemEditing: interactions.toggleItemEditing,
                            toggleItemAddChild: () => handleAddCategoryChild(props.itemId),
                            toggleItemDeleting: () => handleDeleteCategory(props.itemId),
                        } as CustomLabelProps,
                        labelInput: {
                            onBlur: handleInputBlur,
                            onKeyDown: handleInputKeyDown,
                            handleCancelItemLabelEditing: interactions.handleCancelItemLabelEditing,
                            handleSaveItemLabel: interactions.handleSaveItemLabel,
                            onChange: (e) => handleInputChange(e, props.itemId),
                            error
                        } as CustomLabelInputProps,
                    }}
                />

                {categoryToBeDeleted && (
                    <SocietyAdminCategoriesDeletionModal open={open} setOpen={setOpen} category={categoryToBeDeleted}/>
                )}

            </>
        );
    });

    return (
        <>
            <Box sx={{
                width: "100%",
                backgroundColor: 'secondary.main',
                alignContent: 'center'
            }}>
                <Box
                    sx={{
                        backgroundColor: "secondary.main",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className={styles.content}>
                        <div className={styles.realContent}>
                            <Typography
                                variant={"secondVariant"}
                                sx={{
                                    fontSize: {xs: 25, sm: 30}
                                }}
                            >
                                Catégories de produits
                            </Typography>

                            <Box sx={{minWidth: 260}}>
                                <RichTreeView
                                    items={treeViewCategories}
                                    slots={{item: CustomTreeItem2}}
                                    experimentalFeatures={{labelEditing: true}}
                                    isItemEditable
                                    defaultExpandedItems={['grid', 'pickers']}
                                    expansionTrigger="iconContainer"
                                    onItemLabelChange={(itemId, label) => editProductCategory(itemId, label)}
                                />
                            </Box>

                            <Button
                                variant={"firstVariant"}
                                sx={{
                                    mt: 4,
                                    mb: 2,
                                }}
                                onClick={() => addParentProductCategory()}
                            >
                                Ajouter une catégorie principale
                            </Button>
                        </div>
                    </div>
                </Box>
            </Box>
            <SocietyAdminCategoriesAddModal open={addOpen} setOpen={setAddOpen} parentCategory={parentCategoryToAddChild} setParentCategory={setParentCategoryToAddChild}/>
        </>
    );
}

export default SocietyAdminCategories