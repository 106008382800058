import React, {useEffect, useState} from "react";
import {useSectionsRefs} from "../../../components/mainWrapper/MainWrapper";
import styles from "./SocietyArticles.module.css"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Grid2} from "@mui/material";
import {
    ProductDTO
} from "../../../components/societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import useDataFetch from "../../../hooks/useDataFetch";
import useSociety from "../../../hooks/useSociety";
import CartOverlay from "../../../components/cartOverlay/CartOverlay";
import {useCartOverlay} from "../../../hooks/useCart";
import Button from "@mui/material/Button";
import AddArticleModal from "../../../components/societyAdmin/societyAdminArticles/addArticleModal/AddArticleModal";
import {ProductCategoryDTO} from "../../../context/SocietyProvider";
import {useNavigate, useParams} from "react-router-dom";
import {getPathToSubcategoryEntry} from './articlesUtils'
import SocietyArticlesList from "./societyArticlesList/SocietyArticlesList";
import CategoryPath from "./categoryPath/CategoryPath";

const SocietyArticles = () => {

    const {society} = useSociety();

    const {categoryId} = useParams();
    const categoryIdNumber = categoryId ? Number(categoryId) : 0;

    const {isCartOpen, closeCart} = useCartOverlay();

    const [openAddArticleModal, setOpenAddArticleModal] = useState<boolean>(false);

    const {setNavItems, setDisplayRegisterSocietyButton} = useSectionsRefs();

    const {data: articles} = useDataFetch<ProductDTO[]>(`/societies/${society.id}/products`, true, true);

    const navigate = useNavigate();

    useEffect(() => {
        setNavItems([]);
        setDisplayRegisterSocietyButton(false);
    }, [setDisplayRegisterSocietyButton, setNavItems]);

    let categoryPath: ProductCategoryDTO[] = [];

    if (categoryIdNumber !== 0) {
        categoryPath = getPathToSubcategoryEntry(society, categoryIdNumber) || [];
    }
    const currentCategories: ProductCategoryDTO[] = categoryPath.length > 0 ? categoryPath[categoryPath.length - 1].subcategories : society.categories!;

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: 'secondary.main',
        }}>

            <Box
                sx={{
                    backgroundColor: "secondary.main",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <div className={styles.realContent}>

                        <CategoryPath categoryPath={categoryPath}/>

                        {articles && articles.length === 0 && (
                            <Typography
                                sx={{
                                    color: '#ffffff',
                                    fontSize: {xs: 22, sm: 26},
                                }}
                                variant={"firstVariant"}
                            >
                                Aucun article n'est disponible actuellement. Revenez plus tard !
                            </Typography>
                        )}

                        {currentCategories.length > 0 && (
                            <Grid2 container spacing={2} mt={4}>
                                {currentCategories.map((category: ProductCategoryDTO) => (
                                    <Grid2
                                        key={category.id}
                                        size={{xs: 6, sm: 4, md: 4}}
                                        sx={{
                                            backgroundColor: '#ffffff',
                                            minHeight: '50px',
                                            alignContent: 'center'
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            onClick={() => navigate(`/categories/${category.id}`)}
                                        >
                                            <Typography
                                                variant={"thirdVariant"}
                                                fontSize={{xs: '16x', sm: '18px', md: '18px'}}
                                            >
                                                {category.name}
                                            </Typography>
                                        </Button>
                                    </Grid2>
                                ))}
                            </Grid2>
                        )}

                        {articles && (
                            <SocietyArticlesList categoryId={categoryIdNumber} articles={articles}/>
                        )}

                    </div>
                </div>

            </Box>

            {society.secured && (
                <Button
                    sx={{
                        mt: 0,
                        mb: 4,
                    }}
                    variant={"fourthVariant"}
                    onClick={() => setOpenAddArticleModal(true)}
                >
                    Ajouter un article
                </Button>
            )}

            <CartOverlay isCartOpen={isCartOpen} closeCart={closeCart}/>
            <AddArticleModal open={openAddArticleModal} setOpen={setOpenAddArticleModal}/>
        </Box>
    );
}

export default SocietyArticles