import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

interface RegistrationConfirmationStepProps {
    email: string,
    isSociety?: boolean,
}

const RegistrationConfirmationStep = ({email, isSociety = true}: RegistrationConfirmationStepProps) => {

    return (
        <Box
            sx={{
                pl: "10px",
                pr: "10px",
                pb: "10px",
            }}
        >
            <Typography
                variant={"secondVariant"}
                sx={{
                    fontSize: {xs: 22, sm: 26, md: 30},
                    mb: 1,
                }}
            >
                Bienvenue chez Looty !
            </Typography>
            <Typography
                textAlign={"left"}
                variant={"secondVariant"}
                sx={{
                    fontSize: {xs: 16},
                    mb: 3,
                }}
            >

                {isSociety ?
                    `Félicitations, l'inscription de votre société sur notre plateforme s'est déroulée avec succès ! Il ne vous reste plus qu'à confirmer cette dernière en cliquant sur le lien qui vous a été envoyé sur votre adresse mail (${email}). Vous pourrez ensuite démarrer la personnalisation de votre page Looty et la rendre publique à vos clients. Veuillez noter que toutes les informations que vous venez de fournir sont modifiables à tout moment.`
                    :
                    `Félicitations, votre inscription sur notre plateforme s'est déroulée avec succès ! Il ne vous reste plus qu'à confirmer cette dernière en cliquant sur le lien qui vous a été envoyé sur votre adresse mail (${email}). Veuillez noter que toutes les informations que vous venez de fournir sont modifiables à tout moment dans votre profil.`
                }
            </Typography>
            <Typography
                textAlign={"left"}
                variant={"secondVariant"}
                sx={{
                    fontSize: {xs: 16},
                    mb: 3,
                }}
            >
                Vous avez fait une erreur en spécifiant votre adresse email ou vous n'avez pas reçu de mail de
                confirmation ?
                Pas de problème, contactez-nous sur info@looty.ch et nous corrigerons cela pour vous dans les plus brefs
                délais.
            </Typography>

            <Typography
                textAlign={"left"}
                variant={"secondVariant"}
                sx={{
                    fontSize: {xs: 16},
                }}
            >
                Vous pouvez dès à présent fermer cette page, la suite se déroule dans votre boîte mail. Pensez à
                vérifier vos spams.
            </Typography>
        </Box>
    );
}

export default RegistrationConfirmationStep