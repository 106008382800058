import {ProductCategoryDTO, SocietyDTO} from "../../../context/SocietyProvider";

export const getPathToSubcategory = (rootCategory: ProductCategoryDTO, subcategoryId: number): ProductCategoryDTO[] | null => {
    function traverse(
        category: ProductCategoryDTO,
        currentPath: ProductCategoryDTO[]
    ): ProductCategoryDTO[] | null {
        if (category.id === subcategoryId) {
            return [...currentPath, category]; // Found the subcategory
        }

        if (category.subcategories) {
            for (const subcategory of category.subcategories) {
                const foundPath = traverse(subcategory, [...currentPath, category]);
                if (foundPath) {
                    return foundPath; // Path found in a subcategory
                }
            }
        }

        return null; // Subcategory not found in this branch
    }

    return traverse(rootCategory, []);
}

export const getPathToSubcategoryEntry = (society: SocietyDTO, subcategoryId: number): ProductCategoryDTO[] | null => {
    if (society.categories) {
        for (let i = 0; i < society.categories?.length; i++) {
            const result = getPathToSubcategory(society.categories[i], subcategoryId);
            if (result) {
                return result;
            }
        }
    }
    return null;
}

export const mapCategoriesPathToIds = (society: SocietyDTO, categoryId: number): number[] => {
    const pathToSubcategoryEntry = getPathToSubcategoryEntry(society, categoryId);
    if (!pathToSubcategoryEntry) {
        return [];
    }
    return pathToSubcategoryEntry.map((category) => category.id);
}